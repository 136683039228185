////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useReducer} from 'react';
import {Task} from '../dataModel/Task';
import {CenteringContainer, FlexColumn, FlexRow, FlexRowCentered} from '../CommonStyledComponents';
import ChecksetDetail from './ChecksetDetail';
import {ChecksetState} from './states/ChecksetState';
import {reducer} from './reducers/CheckSetReducer';
import {CheckSetService} from '../services/CheckSetService';
import {CheckSetActions} from '../Enums';
import {CheckSet, EditCheckSetRequest} from '../clients/Classes';
import {CheckSetsMatch} from '../Utility';
import CheckSetListItem from './CheckSetListItem';
import {PageSizeService} from "../services/PageSizeService";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Theme from "@adsk/alloy-react-theme";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import Accordion, {AccordionItem} from "@adsk/alloy-react-accordion";
import Dropzone from '@adsk/alloy-react-dropzone';

const service = new CheckSetService();

const pageSize = PageSizeService.GetPageSize('checkset');
let paginationToken: string | undefined = undefined;

const SettingsCheckset = (
  {
    task,
    onChanged,
    onError
  }: {
    task: Task | undefined,
    onChanged?: (checkset: CheckSet) => void,
    onError?: (error: any, operation: string) => void
  }) => {
  const defaultState = new ChecksetState();
  defaultState.SelectedCheckset = task?.Checkset;

  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    let isMounted = true;

    dispatch({
      type: CheckSetActions.multipleActions,
      payload: {LoadingPublic: true, LoadingPrivate: true}
    });
    service.GetPublicChecksets()
      .then(checksets => {
        if (!isMounted) {
          return;
        }

        dispatch({
          type: CheckSetActions.multipleActions,
          payload: {LoadingPublic: false, PublicCheckSets: checksets}
        });
      })
      .catch(error => handleError(error, 'Get public checksets'));

    loadChecksets(true);

    return () => {
      isMounted = false;
    };
  }, []);

  function loadChecksets(isFirstLoad: boolean): void {
    dispatch({
      type: CheckSetActions.multipleActions,
      payload: {LoadingMorePrivate: !isFirstLoad, LoadingPrivate: isFirstLoad}
    });

    if (isFirstLoad) {
      paginationToken = undefined;
    }

    service.GetPrivateChecksets(paginationToken, pageSize)
      .then(paginated => {
        paginationToken = paginated.paginationData?.paginationToken;
        const checkSets = paginated.items;

        if (!isFirstLoad) {
          state.PrivateCheckSets.forEach(c => checkSets?.push(c));
        }

        dispatch({
          type: CheckSetActions.multipleActions,
          payload: {
            LoadingPrivate: false,
            PrivateCheckSets: checkSets,
            LoadingMorePrivate: false,
            HasMorePrivate: !paginated.isDone
          }
        });
      })
      .catch(error => handleError(error, 'Private checksets'));
  }

  function setSelected(checkset: CheckSet): void {
    if (task == null) {
      return;
    }
    task.Checkset = checkset;
    dispatch({type: CheckSetActions.selected, payload: checkset});
    if (onChanged) {
      onChanged(checkset);
    }
  }

  function saveCheckSet(checkset: CheckSet): void {
    service.EditCheckSet(checkset.id!, new EditCheckSetRequest({
      name: checkset.name,
      description: checkset.description
    }))
      .then(checkset => {
        console.log(checkset);
      })
      .catch(error => handleError(error, 'Save Checkset'));
  }

  function deleteCheckSet(checkset: CheckSet): void {
    service.DeleteCheckSet(checkset.id!)
      .then(() => {
        const found = state.PrivateCheckSets.find(c => c.id === checkset.id);
        if (found == null) {
          return;
        }

        const isSelected = state.SelectedCheckset?.id === found.id;
        const index = state.PrivateCheckSets.indexOf(found);
        state.PrivateCheckSets.splice(index, 1);
        if (isSelected) {
          dispatch({
            type: CheckSetActions.multipleActions,
            payload: {PrivateChecksets: [...state.PrivateCheckSets], SelectedCheckset: undefined}
          });
        } else {
          dispatch({type: CheckSetActions.privateCheckSets, payload: [...state.PrivateCheckSets]});
        }
      })
      .catch(error => handleError(error, 'Delete Checkset'));
  }

  async function onDrop(accepted: File[], rejected: any[]): Promise<void> {
    if (rejected.length > 0) {
      const messages = rejected.map(r => {
        const errors = r.errors.map((e: any) => e.message);
        return `${r.file.path}: ${errors.join(', ')}`;
      });

      alert(`Some files were not able to be uploaded:\n\n${messages.join('\n')}`);
    }

    dispatch({type: CheckSetActions.uploading, payload: true});
    let completed = 0;
    const target = accepted.length;
    const promises = accepted.map(f => {
      return service.CreateCheckSet(f)
        .then(c => {
          completed++;
          const newCheckSets: CheckSet[] = [];
          state.PrivateCheckSets.forEach(p => newCheckSets.push(p));
          newCheckSets.push(c);
          state.PrivateCheckSets = newCheckSets;
          if (completed >= target) {
            dispatch({
              type: CheckSetActions.multipleActions,
              payload: {Uploading: false, PrivateCheckSets: newCheckSets}
            });
          }
        })
        .catch(error => handleError(error, 'Upload Checkset'));
    });

    await Promise.all(promises);
  }

  function handleError(error: any, operation: string): void {
    if (onError) {
      onError(error, operation);
    }
  }

  return (
    <FlexRow>
      <div style={{flex: '1'}}>
        <Accordion>
          <AccordionItem
            title={<span style={Theme.typography.heading2}>Public Library</span>}>
            <div style={{maxHeight: '500px', overflow: 'auto'}}>
              {
                state.LoadingPublic && <ProgressRing size={'large'} style={{margin: '3em'}}/>
              }
              {
                !state.LoadingPublic && state.PublicCheckSets.length === 0 &&
                <CenteringContainer style={{margin: '3em'}}>No Checksets
                  Available</CenteringContainer>
              }
              {
                !state.LoadingPublic && state.PublicCheckSets.length > 0 &&
                state.PublicCheckSets.map(c =>
                  <CheckSetListItem checkset={c}
                                    isSelected={CheckSetsMatch(c, state.SelectedCheckset)}
                                    key={c.id ?? c.url}
                                    onClick={() => setSelected(c)}/>
                )
              }
            </div>
          </AccordionItem>
          <AccordionItem
            title={<span style={Theme.typography.heading2}>My Library</span>}>
            <FlexColumn style={{maxHeight: '500px', overflow: 'auto'}}>
              {
                state.LoadingPrivate && <ProgressRing size={'large'} style={{margin: '3em'}}/>
              }
              {
                !state.LoadingPrivate && state.PrivateCheckSets.length === 0 &&
                <CenteringContainer style={{margin: '3em 0'}}>
                  No Checksets Available
                </CenteringContainer>
              }
              {
                !state.LoadingPrivate && state.PrivateCheckSets.length > 0 &&
                state.PrivateCheckSets.map(c =>
                  <CheckSetListItem checkset={c}
                                    isSelected={CheckSetsMatch(c, state.SelectedCheckset)}
                                    key={c.id ?? c.url}
                                    editable={true}
                                    onEdit={() => saveCheckSet(c)}
                                    onDelete={() => deleteCheckSet(c)}
                                    onClick={() => setSelected(c)}/>
                )
              }
              {
                state.HasMorePrivate &&
                <LoadMoreDataRow isLoading={state.LoadingMorePrivate} onLoad={async () => loadChecksets(false)}/>
              }
              {
                !state.LoadingPrivate &&
                <>
                  <p style={Theme.typography.bodyMediumBold}>Upload your Checkset</p>
                  <p style={Theme.typography.bodyMedium}>Select and upload your local checkset xml
                    file by using the 'Choose Files' link below or simply drop the file on the
                    upload control.</p>
                  <div style={{display: 'grid'}}>
                    <Dropzone
                      style={{gridArea: '1/1', zIndex: 0}}
                      size={Dropzone.SIZES.SMALL}
                      disabled={state.Uploading}
                      accept={'.xml'}
                      onDrop={onDrop}/>
                    {
                      state.Uploading &&
                      <FlexColumn style={{
                        gridArea: '1/1',
                        zIndex: 10,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: '1em'
                      }}>
                        <FlexRowCentered>
                          <ProgressRing size={'small'} style={{marginRight: '1em'}}/>
                          <span style={Theme.typography.bodyMedium}>Uploading Files</span>
                        </FlexRowCentered>
                      </FlexColumn>
                    }
                  </div>
                </>
              }
            </FlexColumn>
          </AccordionItem>
        </Accordion>
      </div>
      <div style={{flex: '1', padding: '1em'}}>
        <ChecksetDetail checkset={task?.Checkset}/>
      </div>
    </FlexRow>
  );
};

export default SettingsCheckset;