////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class ConvertDate {
    public static Convert(value: Date | undefined): string {
        if (value == null) {
            return 'n/a';
        } else {
            return `${value.getFullYear()}-${this.twoDigit(value.getMonth())}-${this.twoDigit(value.getDay())}_${this.twoDigit(value.getHours())}-${this.twoDigit(value.getMinutes())}-${this.twoDigit(value.getSeconds())}`;
        }
    }

    static twoDigit(value: number): string {
        const int = value.toFixed(0).toString();
        return int.padStart(2, '0');
    }
}