////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect} from 'react';
import './App.css';
import MainContent from "./components/MainContent";
import {BrowserRouter as Router, Navigate, Route} from "react-router-dom";
import NotFound from "./pages/NotFound";
import {Routes} from "react-router";
import {PAGES, PATHS} from "./Constants";
import Door from "./pages/Door";
import Landing from "./pages/Landing";
import RequireAuth from "./components/RequireAuth";
import {pages} from "./pages/Routes";
import TaskEdit from "./pages/TaskEdit";
import {PageTypes} from "./Enums";
import ReportView from "./pages/ReportView";

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_HEADER_TAG != null
      && process.env.REACT_APP_HEADER_TAG !== ''
      && !document.title.startsWith(process.env.REACT_APP_HEADER_TAG)) {
      document.title = `${process.env.REACT_APP_HEADER_TAG} | ${document.title}`;
    }
  }, []);

  function getPageContent(page: {
    path: string,
    title: string,
    uiVisible: boolean,
    component: ReactElement,
    exact: boolean,
    type: PageTypes,
  }): any {
    switch (page.type) {
      case PageTypes.protected:
      case PageTypes.customers:
      case PageTypes.usage:
      case PageTypes.settings:
        return <Route
          key={page.path}
          path={page.path}
          element={<RequireAuth>{page.component}</RequireAuth>}
        />;
      case PageTypes.auth:
        return undefined;
    }
  }

  return (
    <Router>
      <Routes>
        <Route path={PATHS[PAGES.ROOT]} element={<RequireAuth><MainContent/></RequireAuth>}>
          {pages.map(page => getPageContent(page))}
          <Route key={'/tasks/edit'}
                 path={`${PATHS[PAGES.TASKS]}/:id/:action?`}
                 element={<RequireAuth><TaskEdit/></RequireAuth>}/>
          <Route key={'/reportView'}
                 path={`${PATHS[PAGES.REPORT]}/:id`}
                 element={<RequireAuth><ReportView/></RequireAuth>}/>
          <Route path={''} element={<Navigate to={`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASKS]}`} replace/>}/>
        </Route>
        <Route path={PATHS[PAGES.DOOR]} element={<Door/>}/>
        <Route path={`${PATHS[PAGES.LANDING]}`} element={<Landing/>}/>
        <Route path={''} element={<Navigate to={PATHS[PAGES.DOOR]} replace/>}/>
        <Route key={'catch'} path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;
