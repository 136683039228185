////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {Task} from "../dataModel/Task";
import {ProjectUI} from "../dataModel/ProjectUI";
import ProjectDropdown from "./ProjectDropdown";
import {
  BlueButton,
  BorderSection,
  CenteringContainer,
  FlexColumn,
  FlexFill,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import FileStructureList from "./FileStructureList";
import {FileUI} from "../dataModel/FileUI";
import {ConvertDate} from '../converters/ConvertDate';
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {array_move} from "../Utility";
import styled from "styled-components";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {getLoadingCell} from '../UiUtility';
import Accordion, {AccordionItem} from '@adsk/alloy-react-accordion';
import Illustration from '@adsk/alloy-react-illustration';
import Theme from "@adsk/alloy-react-theme";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Tooltip from '@adsk/alloy-react-tooltip';
import {IconButton} from '@adsk/alloy-react-button';
import {ChevronDownIcon, ChevronUpIcon, DocumentPlusCircleIcon, XIcon} from '@adsk/alloy-react-icon';
import {OverflowTooltip} from '@adsk/alloy-react-tooltip';
import Modal from '@adsk/alloy-react-modal';
import Table from './Table';

const ActionContainer = styled.div`
  width: 20px;
`;

const SettingsModels = (
  {
    task,
    projects,
    loadingProjects,
    selectedProject,
    recentModels,
    loadingRecent,
    expandedIds,
    onExpandedChanged,
    onProjectSelected,
    onChanged
  }: {
    task: Task | undefined,
    projects: ProjectUI[],
    loadingProjects: boolean,
    selectedProject: ProjectUI | undefined,
    recentModels: FileUI[],
    loadingRecent: boolean,
    expandedIds?: string[],
    onExpandedChanged?: (expandedIds: string[]) => void,
    onProjectSelected?: (project: ProjectUI | undefined) => void,
    onChanged?: () => void
  }
) => {
  const [models, setModels] = useState(task?.Models);
  const [folders, setFolders] = useState(task?.Directories);
  const [showResetWarning, setShowResetWarning] = useState(false);

  function addModel(model: FileUI): void {
    if (task == null) {
      return;
    }

    if (model != null && task.Models.find(m => m.Id === model.Id) == null) {
      model.HubId = selectedProject?.HubId;
      task.Models.push(model);
      setNewModels(task.Models);
    }
  }

  function removeModel(model: FileUI): void {
    if (task == null) {
      return;
    }
    const index = task.Models.indexOf(model);
    task.Models.splice(index, 1);
    setNewModels(task.Models);
  }

  function moveModel(model: FileUI, moveUp: boolean): void {
    if (task == null) {
      return;
    }

    const index = task.Models.indexOf(model);
    const newIndex = moveUp ? index - 1 : index + 1;

    array_move(task.Models, index, newIndex);

    setNewModels(task.Models);
  }

  function addDirectory(directory: DirectoryUI, recursive: boolean): void {
    if (task == null) {
      return;
    }

    if (directory != null && task.Directories.find(d => d.Id === directory.Id) == null) {
      directory.Recursive = recursive;
      directory.HubId = selectedProject?.HubId;
      task.Directories.push(directory);
      setNewDirectories(task.Directories);
    }
  }

  function removeDirectory(directory: DirectoryUI): void {
    if (task == null) {
      return;
    }

    const index = task.Directories.indexOf(directory);
    task.Directories.splice(index, 1);

    setNewDirectories(task.Directories);
  }

  function moveDirectory(directory: DirectoryUI, moveUp: boolean): void {
    if (task == null) {
      return;
    }

    const index = task.Directories.indexOf(directory);
    const newIndex = moveUp ? index - 1 : index + 1;

    array_move(task.Directories, index, newIndex);

    setNewDirectories(task.Directories);
  }

  function commitReset(): void {
    if (task == null) {
      return;
    }

    setShowResetWarning(false);

    setNewModels([]);
    setNewDirectories([]);
  }

  function setNewModels(models: FileUI[]): void {
    if (task == null) {
      return;
    }
    const newModels: FileUI[] = [];
    models.forEach(m => newModels.push(m));
    task.Models = newModels;
    setModels(newModels);
    if (onChanged) {
      onChanged();
    }
  }

  function setNewDirectories(directories: DirectoryUI[]): void {
    if (task == null) {
      return;
    }
    const newDirectories: DirectoryUI[] = [];
    directories.forEach(m => newDirectories.push(m));
    task.Directories = newDirectories;
    setFolders(newDirectories);
    if (onChanged) {
      onChanged();
    }
  }

  function onAdd(item: BIM360ItemBase, recursive: boolean): void {
    if (item instanceof FileUI) {
      addModel(item);
    } else if (item instanceof DirectoryUI) {
      addDirectory(item, recursive);
    }
  }
  
  return (
    <FlexRow>
      <FlexFill>
        <FlexColumn>
          <Accordion>
            <AccordionItem
              title={<span style={Theme.typography.heading2}>Autodesk Docs / BIM 360 Docs</span>}>
              {/*TODO: This should be better for max height*/}
              <FlexColumn style={{maxHeight: '500px', overflow: 'auto', padding: '1em 0'}}>
                {
                  loadingProjects &&
                    <CenteringContainer>
                        <ProgressRing size={'large'} style={{margin: '3em'}}/>
                    </CenteringContainer>
                }
                {
                  !loadingProjects && projects.length > 0 &&
                    <>
                        <ProjectDropdown
                            projects={projects}
                            selectedProject={selectedProject}
                            loadingProjects={loadingProjects}
                            onSelected={project => {
                              if (onProjectSelected) {
                                onProjectSelected(project);
                              }
                            }
                            }/>
                        <FileStructureList
                            project={selectedProject}
                            allowMultiSelection={false}
                            allowSelection={false}
                            onAdd={onAdd}/>
                    </>
                }
                {
                  !loadingProjects && projects.length === 0 &&
                    <CenteringContainer style={{flexDirection: 'column'}}>
                        <Illustration type={'folderEmptyGrey'} width={200} height={200}/>
                        <p style={Theme.typography.bodyLarge}>No available projects</p>
                    </CenteringContainer>
                }
              </FlexColumn>
            </AccordionItem>
            <AccordionItem
              title={<span style={Theme.typography.heading2}>Recent</span>}>
              <div style={{maxHeight: '500px', overflow: 'auto', padding: '1em 0'}}>
                {
                  loadingRecent &&
                    <CenteringContainer>
                        <ProgressRing size={'large'} style={{margin: '3em'}}/>
                    </CenteringContainer>
                }
                {
                  !loadingRecent &&
                    <Table<FileUI>
                        data={recentModels}
                        columns={[
                          {
                            id: 'actions',
                            size: 20,
                            cell: d =>
                              <Tooltip content={'Add this model to the task'}>
                                <IconButton onClick={() => addModel(d.row.original)}>
                                  <DocumentPlusCircleIcon/>
                                </IconButton>
                              </Tooltip>
                          },
                          {
                            id: 'HubName',
                            accessorFn: f => f.HubName,
                            header: () => 'Hub',
                            cell: d => getLoadingCell(d.row.original, d.row.original.HubName),
                          },
                          {
                            id: 'ProjectName',
                            accessorFn: f => f.ProjectName,
                            header: () => 'Project',
                            cell: d => getLoadingCell(d.row.original, d.row.original.ProjectName),
                          },
                          {
                            id: 'Name',
                            accessorFn: f => f.Name,
                            header: () => 'Name',
                            cell: d => <OverflowTooltip>{d.row.original.Name}</OverflowTooltip>,
                          },
                          {
                            id: 'LastUpdate',
                            accessorFn: f => f.LastUpdate,
                            header: () => 'Last Update',
                            cell: d =>
                              <OverflowTooltip>{ConvertDate.Convert(d.row.original.LastUpdate)}</OverflowTooltip>,
                          },
                          {
                            id: 'UpdateBy',
                            accessorFn: f => f.UpdateBy,
                            header: () => 'Update By',
                            cell: d => <OverflowTooltip>{d.row.original.UpdateBy}</OverflowTooltip>,
                          },
                        ]}/>
                }
              </div>
            </AccordionItem>
          </Accordion>
        </FlexColumn>
      </FlexFill>
      <FlexFill style={{display: "flex"}}>
        <FlexColumn style={{margin: '1em'}}>
          <FlexRow style={{flex: 0}}>
            <FlexFill/>
            <BlueButton onClick={() => setShowResetWarning(true)}>Reset Lists</BlueButton>
          </FlexRow>
          <p style={Theme.typography.heading2}>List of Models</p>
          <BorderSection style={{flex: 1}}>
            {
              models != null && models.map(m =>
                <FlexRowCentered style={{margin: '0.25em'}} key={m.Id}>
                  <div style={{marginRight: '1em'}}>{models.indexOf(m) + 1}</div>
                  <FlexFill>{m.Name}</FlexFill>
                  <ActionContainer>
                    {
                      models.indexOf(m) !== 0 &&
                        <IconButton onClick={() => moveModel(m, true)}><ChevronUpIcon/></IconButton>
                    }
                  </ActionContainer>
                  <ActionContainer>
                    {
                      models.indexOf(m) < models.length - 1 &&
                        <IconButton onClick={() => moveModel(m, false)}><ChevronDownIcon/></IconButton>
                    }
                  </ActionContainer>
                  <ActionContainer>
                    <IconButton onClick={() => removeModel(m)}><XIcon/></IconButton>
                  </ActionContainer>
                </FlexRowCentered>
              )
            }
          </BorderSection>
          <p style={Theme.typography.heading2}>List of Folders</p>
          <BorderSection style={{flex: 1}}>
            {
              folders != null && folders.map(f =>
                <FlexRowCentered style={{margin: '0.25em'}} key={f.Id}>
                  <div style={{marginRight: '1em'}}>{folders.indexOf(f) + 1}</div>
                  <FlexFill>{f.Name}{f.Recursive && ' (Recursive)'}</FlexFill>
                  <ActionContainer>
                    {
                      folders.indexOf(f) !== 0 &&
                        <IconButton
                            onClick={() => moveDirectory(f, true)}><ChevronUpIcon/></IconButton>
                    }
                  </ActionContainer>
                  <ActionContainer>
                    {
                      folders.indexOf(f) < folders.length - 1 &&
                        <IconButton
                            onClick={() => moveDirectory(f, false)}><ChevronDownIcon/></IconButton>
                    }
                  </ActionContainer>
                  <ActionContainer>
                    <IconButton onClick={() => removeDirectory(f)}><XIcon/></IconButton>
                  </ActionContainer>
                </FlexRowCentered>
              )
            }
          </BorderSection>
        </FlexColumn>
      </FlexFill>

      <Modal open={showResetWarning}>
        <Modal.Header>Reset All?</Modal.Header>
        <Modal.Body>
          <p style={Theme.typography.bodyMediumBold}>This will completely remove all models and folders from
            the list!</p>
          <p style={Theme.typography.bodyMedium}>Are you sure you want to reset all models and folders?</p>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={commitReset}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Reset All</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => setShowResetWarning(false)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </FlexRow>
  );
};

export default SettingsModels;