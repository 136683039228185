////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect, useReducer, useState} from 'react';
import {ReportService} from '../services/ReportService';
import {ReportsState} from './states/ReportsState';
import {reducer} from './reducers/ReportsReducer';
import {GetErrorMessage, OpenUrlInNewTab, uniqueDateDisplayFilter, uniqueFilter} from '../Utility';
import {ReportFormats, ReportsActions, ReportsFunctions} from '../Enums';
import {ConvertRunDate} from '../converters/ConvertRunDate';
import {Constants, PAGES, PATHS} from '../Constants';
import {ReportUI} from '../dataModel/ReportUI';
import {
  BlueButton,
  CenteringContainer,
  ContentScroller,
  ContentWrapper, EllipsisCell,
  FlexColumn, FlexColumnCentered,
  FlexFill,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import FilterItem from './FilterItem';
import {ConvertDuration} from '../converters/ConvertDuration';
import {GetListCell} from '../UiUtility';
import {ConvertSummary} from '../converters/ConvertSummary';
import {ReportTranslator} from "../dataModel/translators/ReportTranslator";
import {CancellationToken} from "../dataModel/CancellationToken";
import PartialLoadWarning from "./ParitalLoadWarning";
import {PageSizeService} from "../services/PageSizeService";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import Tooltip, {OverflowTooltip} from "@adsk/alloy-react-tooltip";
import {BasicButton, IconButton, LinkButton} from "@adsk/alloy-react-button";
import {ArrowRotateTwoIcon, CloudDownArrowIcon, OpenIcon, TrashCanIcon} from "@adsk/alloy-react-icon";
import Theme from "@adsk/alloy-react-theme";
import FilterButton from "@adsk/alloy-react-filter-button";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Illustration from "@adsk/alloy-react-illustration";
import Panel from "@adsk/alloy-react-panel";
import Modal from "@adsk/alloy-react-modal";
import SearchField from "@adsk/alloy-react-search-field";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import Table from "../components/Table";

const service = new ReportService();

const pageSize = PageSizeService.GetPageSize('reports');
let paginationToken: string | undefined = undefined;
const cancelToken = new CancellationToken();

const ReportList = () => {
  const [state, dispatch] = useReducer(reducer, new ReportsState());
  // Need to use 'useState' here because something is broken in the search field component with state binding
  const [search, setSearch] = useState('');
  const [loadingIds, setLoadingIds] = useState<{ [key: string]: boolean }>({'temp': false});

  useEffect(() => {
    loadReports(true, false)
  }, []);

  function loadReports(isFirstLoad: boolean, loadAll: boolean): void {
    dispatch({
      type: ReportsActions.multipleActions,
      payload: {
        loading: isFirstLoad || loadAll,
        loadingMoreData: !loadAll && !isFirstLoad,
        canCancelLoad: loadAll
      }
    });

    if (isFirstLoad) {
      paginationToken = undefined;
    }

    cancelToken.Cancel = false;

    const promise = loadAll
      ? service.GetRemainingReports(paginationToken, pageSize, cancelToken)
      : service.GetReports(paginationToken, pageSize);

    promise
      .then(paginationData => {
        paginationToken = paginationData.paginationData?.paginationToken;
        const reports = paginationData.items!.map(rpt => ReportTranslator.TranslateSummaryReport(rpt));

        if (!isFirstLoad) {
          state.reports.forEach(r => reports.push(r));
        }

        const defaultName = {value: '', label: Constants.NoFilterString};
        const nameOptions = [defaultName];
        const dynamicNames = reports.map(r => r.JobName).filter(uniqueFilter).map(o => {
          return {value: o, label: o};
        });
        for (const name of dynamicNames) {
          nameOptions.push(name);
        }

        const defaultDate = {value: '', label: Constants.NoFilterString};
        const dateOptions = [defaultDate];
        const dynamicDates = reports
          .map(r => r.ReportDate)
          .filter(uniqueDateDisplayFilter)
          .map(o => {
            return {value: ConvertRunDate.Convert(o), label: ConvertRunDate.Convert(o)};
          });
        for (const date of dynamicDates) {
          dateOptions.push(date);
        }

        dispatch({
          type: ReportsActions.multipleActions, payload: {
            reports: reports,
            filteredReports: reports,
            loading: false,
            loadingMoreData: false,
            hasMoreData: !paginationData.isDone,
            filterOptions: [
              {
                id: 'name',
                title: 'Name',
                selected: defaultName,
                options: nameOptions,
              },
              {
                id: 'date',
                title: 'Date',
                selected: defaultDate,
                options: dateOptions,
              }
            ],
            checkedReports: [],
            globalCheck: false
          }
        });

        updateFilteredData(undefined, reports);
      })
      .catch(error => {
        onError(error, 'Get reports');
        dispatch({
          type: ReportsActions.multipleActions,
          payload: {loading: false, loadingMoreData: false}
        });
      });
  }

  function downloadReport(report: ReportUI, format: ReportFormats, combine: boolean = false): void {
    service.GetReportDownload(report, format, combine)
      .then(result => {
        if (!result.isReady) {
          throw Error('Get Download returned with an incomplete result');
        }

        modifyLoading([report.Id], false);
        service.ProcessReportDownload(result, report.JobName);
        return;
      })
      .catch(er => {
        modifyLoading([report.Id], false);
        onError(er, 'Download report');
      });
  }

  function deleteReports(reports: ReportUI[]): void {
    const promises: Promise<boolean>[] = [];

    reports.forEach(report => {
      promises.push(service.DeleteReport(report.Id)
        .catch(er => {
          modifyLoading([report.Id], false);
          alert(GetErrorMessage(er, 'Delete report'));
          return false;
        }));
    });

    Promise.all(promises)
      .then(() => {
        modifyLoading(reports.map(r => r.Id), false);

        const newReports = [...state.reports];

        reports.forEach(report => {
          const found = newReports.find(r => r.Id === report.Id);
          if (found != null) {
            newReports.splice(newReports.indexOf(found), 1);
          }
        });

        dispatch({
          type: ReportsActions.multipleActions,
          payload: {
            reports: newReports
          }
        });

        updateFilteredData(undefined, newReports);
      })
      .catch(error => onError(error, 'Delete reports'));
  }

  function processSelectedReports(f: ReportsFunctions): void {
    if (state.checkedReports.length > 5) {
      dispatch({
        type: ReportsActions.multipleActions,
        payload: {multiFunction: f, showMultiFunctionWarning: true}
      });
    } else {
      finishProcessReports(f);
    }
  }

  function finishProcessReports(f: ReportsFunctions): void {
    dispatch({type: ReportsActions.showMultiFunctionWarning, payload: false});
    const processReports: ReportUI[] = [];
    const newLoading = {...loadingIds};

    for (const id of state.checkedReports) {
      const report = state.reports.find(r => r.Id === id);
      if (report != null) {
        processReports.push(report);
        newLoading[report.Id] = true;
      }
    }

    if (f !== 'open') {
      setLoadingIds(newLoading);
    }

    if (f === 'delete') {
      // Process in batch
      deleteReports(processReports);
      return;
    }

    for (const report of processReports) {
      switch (f) {
        case 'downloadHtml':
          downloadReport(report, 'Html');
          break;
        case 'downloadExcel':
          downloadReport(report, 'Excel');
          break;
        case 'downloadExcelCombined':
          downloadReport(report, 'Excel', true);
          break;
        case 'downloadAvt':
          downloadReport(report, 'AVT');
          break;
        case 'open':
          OpenUrlInNewTab(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.REPORT]}/${report.Id}`);
          break;
      }
    }
  }

  function modifyLoading(ids: string[], isLoading: boolean): void {
    const newLoading = {...loadingIds};
    ids.forEach(id => newLoading[id] = isLoading);
    setLoadingIds(newLoading);
  }

  function searchChanged(e: any): void {
    setSearch(e);
    updateFilteredData(e);
  }

  function updateFilteredData(searchOverride?: string, reportOverride?: ReportUI[]): void {
    const actualSearch = searchOverride ?? search;
    const actualReports = reportOverride ?? state.reports;

    const filteredReports = actualReports.filter(r => {
      if (actualSearch != null && actualSearch !== '' && !r.JobName?.toLowerCase().includes(actualSearch.toLowerCase())) {
        return false;
      }

      for (const filter of state.filterOptions) {
        if (filter.selected != null && filter.selected.value !== '') {
          switch (filter.id) {
            case 'name':
              if (r.JobName !== filter.selected.value) {
                return false;
              }
              break;
            case 'date':
              if (ConvertRunDate.Convert(r.ReportDate) !== filter.selected.value) {
                return false;
              }
              break;
          }
        }
      }

      return true;
    });

    const global = getGlobalCheckState(filteredReports, state.checkedReports);
    dispatch({
      type: ReportsActions.multipleActions,
      payload: {filteredReports: filteredReports, globalCheck: global}
    });
  }

  function renderCheckHeader(): ReactElement {
    return (
      <Checkbox checked={state.globalCheck} onChange={c => globalCheckChange(c)}/>
    );
  }

  function renderCheckCell(report: ReportUI): ReactElement {
    return (
      <Checkbox
        checked={state.checkedReports.includes(report.Id)}
        onChange={c => itemCheckChange(report, c)}/>
    );
  }

  function itemCheckChange(report: ReportUI, checkState: CheckboxState): void {
    const existing = state.checkedReports.indexOf(report.Id);
    const newChecked = [...state.checkedReports];
    if (checkState === true && existing === -1) {
      newChecked.push(report.Id);
    } else if (checkState === false && existing > -1) {
      newChecked.splice(existing, 1);
    }

    const global = getGlobalCheckState(state.filteredReports, newChecked);
    dispatch({
      type: ReportsActions.multipleActions,
      payload: {checkedReports: newChecked, globalCheck: global}
    });
  }

  function globalCheckChange(checkState: CheckboxState): void {
    if (checkState === true) {
      const newCheck = [...state.checkedReports];
      for (const filteredReport of state.filteredReports) {
        if (!newCheck.includes(filteredReport.Id)) {
          newCheck.push(filteredReport.Id);
        }
      }
      dispatch({
        type: ReportsActions.multipleActions,
        payload: {checkedReports: newCheck, globalCheck: true}
      });
    } else if (checkState === false) {
      const newCheck = [...state.checkedReports];
      for (const filteredReport of state.filteredReports) {
        const index = newCheck.indexOf(filteredReport.Id);
        if (index > -1) {
          newCheck.splice(index, 1);
        }
      }
      dispatch({
        type: ReportsActions.multipleActions,
        payload: {checkedReports: newCheck, globalCheck: false}
      });
    } else {
      console.log(checkState);
    }
  }

  function getGlobalCheckState(reports: ReportUI[], checkedIds: string[]): CheckboxState {
    if (checkedIds.length === 0) {
      return false;
    }

    let matchingChecked = 0;
    for (const report of reports) {
      const inChecked = checkedIds.find(r => r === report.Id);
      if (inChecked != null) {
        matchingChecked++;
      }
    }

    return matchingChecked === 0 ? false : matchingChecked === reports.length ? true : 'indeterminate';
  }

  function getMultiFunctionAction(): string {
    switch (state.multiFunction) {
      case 'downloadHtml':
      case 'downloadExcel':
      case 'downloadExcelCombined':
      case 'downloadAvt':
        return 'Download';
      case 'delete':
        return 'Delete';
      case 'open':
        return 'Open';
    }
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  return (
    <Panel.Container>
      <ContentScroller>
        <ContentWrapper>
          <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
            <FlexRowCentered>
              <h1 style={Theme.typography.heading1}>Reports</h1>
              {
                state.hasMoreData && state.reports.length > 0 &&
                <PartialLoadWarning pageSize={pageSize}
                                    onLoadAll={() => loadReports(false, true)}/>
              }
            </FlexRowCentered>
            <FlexFill/>
            <Tooltip content={'Refresh Reports'}>
              <IconButton
                onClick={() => loadReports(true, false)}
                renderIcon={() => <ArrowRotateTwoIcon/>}
                style={{marginRight: '1em'}}/>
            </Tooltip>
            <SearchField value={search} placeholder={'Search reports...'}
                         onChange={searchChanged}
                         style={{width: '300px', marginRight: '1em', alignSelf: 'center'}}/>
            <FilterButton
              filtersOpen={state.filterOptions.findIndex(f => f.selected?.label !== Constants.NoFilterString) >= 0}
              toggleFiltersPanel={() => dispatch({
                type: ReportsActions.filterOpen,
                payload: !state.filterOpen
              })}
              clearFilters={() => null}/>
          </FlexRowCentered>
          {
            !state.loading &&
            <FlexRow style={{flex: 0, marginBottom: '0.5em'}}>
              <BlueButton onClick={() => processSelectedReports('open')}
                          style={{marginRight: '1em'}}
                          disabled={state.checkedReports.length === 0}>
                <OpenIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>View Report</span>
              </BlueButton>
              <BlueButton onClick={() => processSelectedReports('downloadExcel')}
                          style={{marginRight: '1em'}}
                          disabled={state.checkedReports.length === 0}>
                <CloudDownArrowIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Download Excel (Model Only)</span>
              </BlueButton>
              <BlueButton onClick={() => processSelectedReports('downloadExcelCombined')}
                          style={{marginRight: '1em'}}
                          disabled={state.checkedReports.length === 0}>
                <CloudDownArrowIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Download Excel (Full Task)</span>
              </BlueButton>
              <BlueButton onClick={() => processSelectedReports('downloadHtml')}
                          style={{marginRight: '1em'}}
                          disabled={state.checkedReports.length === 0}>
                <CloudDownArrowIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Download HTML</span>
              </BlueButton>
              <BlueButton onClick={() => processSelectedReports('downloadAvt')}
                          style={{marginRight: '1em'}}
                          disabled={state.checkedReports.length === 0}>
                <CloudDownArrowIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Download AVT File</span>
              </BlueButton>
              <BasicButton onClick={() => processSelectedReports('delete')}
                           disabled={state.checkedReports.length === 0}>
                <TrashCanIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Delete</span>
              </BasicButton>
            </FlexRow>
          }
          {
            !state.loading &&
            <FlexColumn style={{flex: 1}}>
              <Table<ReportUI>
                style={state.reports.length === 0 ? {height: 'inherit'} : {}}
                columns={[
                  {
                    id: 'Select',
                    header: () => renderCheckHeader(),
                    cell: d => renderCheckCell(d.row.original),
                    size: 20,
                  },
                  {
                    id: 'JobName',
                    accessorFn: r => r.JobName,
                    header: () => 'Task',
                    cell: d => <EllipsisCell>{d.row.original.JobName}</EllipsisCell>
                  },
                  {
                    id: 'ModelName',
                    accessorFn: r => r.ModelName,
                    header: () => 'Model',
                    cell: d => GetListCell(d.row.original.Models)
                  },
                  {
                    id: 'ReportDate',
                    accessorFn: r => r.ReportDate,
                    header: () => 'Run Date',
                    sortingFn: 'datetime',
                    cell: d => <EllipsisCell>{ConvertRunDate.Convert(d.row.original.ReportDate)}</EllipsisCell>,
                    size: 50
                  },
                  {
                    id: 'Summary',
                    accessorFn: r => ConvertSummary.Convert(r),
                    header: () => 'Summary',
                    cell: d => ConvertSummary.Convert(d.row.original)
                  },
                  {
                    id: 'Duration',
                    accessorFn: r => ConvertDuration.Convert(r.Duration),
                    size: 50,
                    header: () => 'Duration',
                    cell: d =>
                      <OverflowTooltip>{ConvertDuration.Convert(d.row.original.Duration)}</OverflowTooltip>
                  },
                  {
                    id: 'actions',
                    size: 20,
                    cell: d =>
                      <>
                        {
                          loadingIds == null || !loadingIds[d.row.original.Id] ? null :
                            <ProgressRing size={'small'} style={{marginLeft: '0.4em'}}/>
                        }
                        {
                          (loadingIds == null || !loadingIds[d.row.original.Id]) &&
                          <IconButton
                            onClick={() => OpenUrlInNewTab(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.REPORT]}/${d.row.original.Id}`)}>
                            <OpenIcon/>
                          </IconButton>
                        }
                      </>
                  },
                ]}
                data={state.filteredReports}
                renderLastRow={() => state.hasMoreData &&
                  <LoadMoreDataRow
                    isLoading={state.loadingMoreData}
                    onLoad={async () => loadReports(false, false)}
                    renderLoadMore={() =>
                      <FlexRowCentered>
                        <LinkButton onClick={() => loadReports(false, false)}>
                          <span style={Theme.typography.bodySmall}>Load more</span>
                        </LinkButton>
                        <LinkButton onClick={() => loadReports(false, true)} style={{marginLeft: '1em'}}>
                          <span style={Theme.typography.bodySmall}>Load all</span>
                        </LinkButton>
                      </FlexRowCentered>
                    }/>
                }/>
            </FlexColumn>
          }
          {
            state.loading &&
            (<CenteringContainer>
              <FlexColumnCentered>
                <ProgressRing size={'large'}/>
                {
                  state.canCancelLoad &&
                  <LinkButton onClick={() => cancelToken.Cancel = true}>Cancel</LinkButton>
                }
              </FlexColumnCentered>
            </CenteringContainer>)
          }
          {
            !state.loading && state.reports.length === 0 &&
            <CenteringContainer style={{flexDirection: 'column'}}>
              <Illustration type={'pagesTextGrey'} height={200} width={200}/>
              <p style={Theme.typography.bodyLarge}>You don't have any reports yet</p>
            </CenteringContainer>
          }
        </ContentWrapper>
      </ContentScroller>
      <Panel
        title={'Filter Reports'}
        open={state.filterOpen}
        style={{height: 'auto'}}
        onClose={() => dispatch({type: ReportsActions.filterOpen, payload: false})}>
        <Panel.Body>
          {state.filterOptions.map(o => {
            return (
              <FilterItem item={o} selected={o.selected} key={o.title} onSelectionChange={() => updateFilteredData()}/>
            );
          })}
        </Panel.Body>
      </Panel>
      <Modal open={state.showMultiFunctionWarning}>
        <Modal.Header>{getMultiFunctionAction()} Multiple Reports?</Modal.Header>
        <Modal.Body>
          <p style={Theme.typography.bodyMediumBold}>
            You are about to {getMultiFunctionAction().toLowerCase()} {state.checkedReports.length} reports. Are you
            sure?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishProcessReports(state.multiFunction)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Yes</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({
                          type: ReportsActions.showMultiFunctionWarning,
                          payload: false
                        })}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>No</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </Panel.Container>
  );
};

export default ReportList;
