////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router';
import {reducer} from './reducers/TasksReducer';
import {TasksState} from './states/TasksState';
import {TasksActions} from '../Enums';
import {TaskService} from '../services/TaskService';
import {Constants, PAGES, PATHS} from '../Constants';
import {DefaultItem} from '@adsk/alloy-react-dropdown';
import {GetDefaultTaskFilterOptions, GetErrorMessage, uniqueFilter} from '../Utility';
import {FilterItemData} from '../dataModel/FilterItemData';
import {Task} from '../dataModel/Task';
import {JobStatusType} from '../clients/Classes';
import {
  BlueButton,
  CenteringContainer,
  ContentScroller,
  ContentWrapper, EllipsisCell,
  FlexColumn,
  FlexColumnCentered,
  FlexFill,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import {ConvertTaskStatus} from '../converters/ConvertTaskStatus';
import {ConvertRunDate} from '../converters/ConvertRunDate';
import FilterItem from './FilterItem';
import {ConvertRevitVersion} from '../converters/ConvertRevitVersion';
import {GetListCell, getLoadingCell} from '../UiUtility';
import UsageDetail from './UsageDetail';
import {ProjectService} from "../services/ProjectService";
import {TaskTranslator} from "../dataModel/translators/TaskTranslator";
import {CancellationToken} from "../dataModel/CancellationToken";
import PartialLoadWarning from "./ParitalLoadWarning";
import {PageSizeService} from "../services/PageSizeService";
import Tooltip, {OverflowTooltip} from "@adsk/alloy-react-tooltip";
import {BasicButton, IconButton, LinkButton} from "@adsk/alloy-react-button";
import {
  ArrowRotateTwoIcon,
  CalendarIcon,
  DocumentTwoIcon,
  PauseIcon,
  PencilIcon,
  PlusIcon
} from "@adsk/alloy-react-icon";
import {ActionButton} from "@adsk/alloy-react-menu";
import Theme from "@adsk/alloy-react-theme";
import FilterButton from "@adsk/alloy-react-filter-button";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Illustration from "@adsk/alloy-react-illustration";
import Modal from "@adsk/alloy-react-modal";
import StatusColorIndicator from '@adsk/alloy-react-status-color-indicator';
import Panel from '@adsk/alloy-react-panel';
import SearchField from "@adsk/alloy-react-search-field";
import Table from "../components/Table";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {ProjectWiseService} from "../services/ProjectWiseService";
import {NameRetrievalService} from "../services/NameRetrievalService";

const service = new TaskService();
const projectService = new ProjectService();
const nameService = new NameRetrievalService(projectService, new ProjectWiseService());

const pageSize = PageSizeService.GetPageSize('tasks');
let paginationToken: string | undefined = undefined;
const cancelToken = new CancellationToken();

const TaskList = () => {
  const [state, dispatch] = useReducer(reducer, new TasksState());
  const navigate = useNavigate();
  // Need to use 'useState' here because something is broken in the search field component with state binding
  const [search, setSearch] = useState('');

  useEffect(() => {
    loadTasks(true, false);
  }, []);

  function loadTasks(isFirstLoad: boolean, loadAll: boolean): void {
    dispatch({
      type: TasksActions.multipleActions,
      payload: {
        loading: isFirstLoad || loadAll,
        loadingMoreData: !loadAll && !isFirstLoad,
        canCancelLoad: loadAll
      }
    });

    if (isFirstLoad) {
      paginationToken = undefined;
    }

    cancelToken.Cancel = false;

    const promise = loadAll
      ? service.GetRemainingTasks(paginationToken, pageSize, true, false, cancelToken)
      : service.GetTasks(paginationToken, pageSize, true, false);

    promise
      .then(paginationData => {
        paginationToken = paginationData.paginationData?.paginationToken;

        const tasks = paginationData.items!.map(job => TaskTranslator.GetTask(job));

        if (!isFirstLoad) {
          state.tasks.forEach(t => tasks.push(t));
        }

        dispatch({
          type: TasksActions.multipleActions, payload: {
            tasks: tasks,
            filteredTasks: tasks,
            loading: false,
            loadingMoreData: false,
            hasMoreData: !paginationData.isDone,
          }
        });

        setSearch('');

        nameService.GetProjectAndHubNames(tasks)
          .then(() => {
            const defaultProjects = {value: '', label: Constants.NoFilterString};
            const projectOptions = [defaultProjects];
            const dynamicProjects: DefaultItem[] = tasks
              .map(t => t.ProjectName ?? '-unknown-')
              .filter(uniqueFilter)
              .map(o => {
                return {value: o, label: o}
              });
            dynamicProjects.forEach(p => projectOptions.push(p));

            const defaultHubs = {value: '', label: Constants.NoFilterString};
            const hubOptions = [defaultHubs];
            const dynamicHubs: DefaultItem[] = tasks
              .map(t => t.HubName ?? '-unknown-')
              .filter(uniqueFilter)
              .map(o => {
                return {value: o, label: o}
              });
            dynamicHubs.forEach(p => hubOptions.push(p));

            const newFilterOptions: FilterItemData[] = [];

            GetDefaultTaskFilterOptions().forEach(o => {
              newFilterOptions.push(o);
            });

            newFilterOptions.push({
              id: 'hub',
              title: 'Source Hub',
              selected: defaultHubs,
              options: hubOptions,
            });

            newFilterOptions.push({
              id: 'project',
              title: 'Source Project',
              selected: defaultProjects,
              options: projectOptions,
            });

            dispatch({
              type: TasksActions.multipleActions, payload: {
                tasks: tasks,
                filteredTasks: tasks,
                filterOptions: newFilterOptions,
              }
            });
          });
      })
      .catch(error => {
        alert(GetErrorMessage(error, 'Get tasks'));
        dispatch({type: TasksActions.loading, payload: false});
      });
  }

  function editTask(task: Task): void {
    navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASKS]}/${task.Id}`);
  }

  function pauseTask(task: Task): void {
    service.ToggleTaskPause(task)
      .then(success => {
        if (!success) {
          alert('Failed to toggle task pause');
        }
        dispatch({
          type: TasksActions.multipleActions, payload: {
            tasks: [...state.tasks],
            filteredTasks: [...state.filteredTasks],
          }
        });
      });
  }

  function duplicateTask(task: Task): void {
    navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASKS]}/${task.Id}/duplicate`);
  }

  function searchChanged(e: any): void {
    setSearch(e);
    updateFilteredData(e);
  }

  function updateFilteredData(searchOverride?: string): void {
    const actualSearch = searchOverride ?? search;

    const filteredTasks = state.tasks.filter(t => {
      if (actualSearch != null && actualSearch !== '' && !t.Name?.toLowerCase().includes(actualSearch.toLowerCase())) {
        return false;
      }

      for (const filter of state.filterOptions) {
        if (filter.selected != null && filter.selected.value !== '') {
          switch (filter.id) {
            case 'status':
              if (t.Trigger === 'OnPublish') {
                return t.RawStatus === filter.selected.value || filter.selected.value === 'Monitoring';
              }
              if (t.RawStatus !== filter.selected.value) {
                return false;
              }
              break;
            case 'project':
              if (t.ProjectName !== filter.selected.value) {
                return false;
              }
              break;
            case 'hub':
              if (t.HubName !== filter.selected.value) {
                return false;
              }
              break;
          }
        }
      }

      return true;
    });

    dispatch({type: TasksActions.filteredTasks, payload: filteredTasks});
  }

  function handleAction(action: string[], task: Task): void {
    switch (action[0]) {
      case 'runNow':
        service.RunTask(task.Id!)
          .then(jobRun => {
            const updatedTask = state.tasks.find(t => t.Id === jobRun.jobId);
            if (updatedTask == null) {
              alert('Could not find task in UI, please refresh');
              return;
            }

            // @ts-ignore - This is apparently not preferred, but it works for now...
            updatedTask.RawStatus = JobStatusType[jobRun.status.toString()];
          });
        break;
      case 'remove':
        service.DeleteTask(task)
          .then(success => {
            if (!success) {
              alert('Failed to remove task');
              return;
            }

            const index = state.tasks.indexOf(task);
            if (index !== -1) {
              state.tasks.splice(index, 1);
              dispatch({type: TasksActions.tasks, payload: [...state.tasks]});
              updateFilteredData();
            }
          });
        break;
    }
  }

  function renderStatusCell(task: Task): ReactElement {
    let color = 'green';
    switch (task.RawStatus) {
      case JobStatusType.Scheduled:
        color = 'green';
        break;
      case JobStatusType.Completed:
        color = 'green';
        break;
      case JobStatusType.Paused:
        color = 'yellow';
        break;
      case JobStatusType.Running:
        color = 'green';
        break;
      case JobStatusType.Error:
        color = 'red';
        break;
      case JobStatusType.PartiallyCompleted:
        color = 'yellow';
        break;
      case JobStatusType.PostProcessing:
        color = 'green';
        break;
    }
    return (
      <FlexRowCentered>
        <StatusColorIndicator statusColor={color} style={{marginRight: '0.25em'}}/>
        <OverflowTooltip style={{alignSelf: 'center', marginLeft: '0.5em'}}>
          {ConvertTaskStatus.Convert(task.RawStatus, task.Trigger === 'OnPublish')}
        </OverflowTooltip>
      </FlexRowCentered>
    );
  }

  function renderActionCell(task: Task): ReactElement {
    return <FlexRow>
      <Tooltip content={'Edit this task'}>
        <BasicButton onClick={() => editTask(task)}>
          <PencilIcon/>
        </BasicButton>
      </Tooltip>
      <Tooltip
        content={
          task.Trigger !== 'Recurring' && task.Trigger !== 'OnPublish'
            ? undefined
            : task.IsPaused ? 'Unpause this task' : 'Pause this task'
        }>
        <BasicButton onClick={() => pauseTask(task)}
                     style={
                       task.Trigger !== 'Recurring' && task.Trigger !== 'OnPublish'
                         ? {cursor: 'inherit'}
                         : {}
                     }
                     disabled={task.Trigger !== 'Recurring' && task.Trigger !== 'OnPublish'}>
          <div style={{position: 'relative', width: '24px', height: '24px'}}>
            <CalendarIcon style={{position: 'absolute', left: '0', top: '0'}}/>
            {task.IsPaused && <div style={{
              position: 'absolute',
              right: '0',
              bottom: '0',
              width: '12px',
              height: '12px',
              background: 'white'
            }}>
              <PauseIcon style={{
                width: '12px',
                height: '12px',
              }}/>
            </div>}
          </div>
        </BasicButton>
      </Tooltip>
      <Tooltip content={'Duplicate this task'}>
        <BasicButton onClick={() => duplicateTask(task)}>
          <DocumentTwoIcon/>
        </BasicButton>
      </Tooltip>
      <ActionButton style={{borderWidth: 0}}
                    horizontal={false}
                    options={[
                      {key: 'remove', label: 'Remove'},
                      {key: 'runNow', label: 'Run Now'},
                    ]} onChange={e => handleAction(e, task)}/>
    </FlexRow>;
  }

  return (
    <Panel.Container>
      <ContentScroller>
        <ContentWrapper>
          <FlexRowCentered>
            <h1 style={Theme.typography.heading1}>Tasks</h1>
            {
              state.hasMoreData && state.tasks.length > 0 &&
              <PartialLoadWarning pageSize={pageSize}
                                  onLoadAll={() => loadTasks(false, true)}/>
            }
          </FlexRowCentered>
          <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
            <BlueButton onClick={() => navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASKS]}/new`)}>
              <FlexRowCentered>
                <PlusIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>New Task</span>
              </FlexRowCentered>
            </BlueButton>
            <FlexFill/>
            <Tooltip content={'Refresh Tasks'}>
              <IconButton
                onClick={() => loadTasks(true, false)}
                renderIcon={() => <ArrowRotateTwoIcon/>}
                style={{marginRight: '1em'}}/>
            </Tooltip>
            <SearchField value={search} onChange={searchChanged} placeholder={'Search tasks...'}
                         style={{width: '300px', marginRight: '1em'}}/>
            <FilterButton
              filtersOpen={state.filterOptions.findIndex(f => f.selected?.label !== Constants.NoFilterString) >= 0}
              toggleFiltersPanel={() => dispatch({
                type: TasksActions.filterOpen,
                payload: !state.filterOpen
              })}
              clearFilters={() => null}/>
          </FlexRowCentered>
          {
            !state.loading &&
            <FlexColumn style={{flex: 1}}>
              <Table<Task>
                style={state.filteredTasks.length === 0 ? {height: 'inherit'} : {}}
                columns={[
                  {
                    id: 'RawStatus',
                    accessorFn: t => t.RawStatus,
                    header: () => 'Status',
                    size: 120,
                    cell: d => renderStatusCell(d.row.original)
                  },
                  {
                    id: 'Name',
                    accessorFn: t => t.Name,
                    header: () => 'Name',
                    cell: d => <EllipsisCell>{d.row.original.Name}</EllipsisCell>
                  },
                  {
                    id: 'HubName',
                    accessorFn: t => t.HubName,
                    header: () => 'Hub',
                    cell: d => getLoadingCell(d.row.original, d.row.original.HubName)
                  },
                  {
                    id: 'ProjectName',
                    accessorFn: t => t.ProjectName,
                    header: () => 'Project',
                    cell: d => getLoadingCell(d.row.original, d.row.original.ProjectName)
                  },
                  {
                    id: 'Models_Folders',
                    accessorFn: t => t.ModelFilterField,
                    header: () => 'Models/Folders',
                    cell: d => GetListCell(d.row.original.Models.map(m => m as BIM360ItemBase).concat(d.row.original.Directories.map(d => d as BIM360ItemBase))),
                  },
                  {
                    id: 'RevitVersion',
                    accessorFn: t => t.RevitVersion,
                    size: 100,
                    header: () => 'Revit Version',
                    cell: d =>
                      <OverflowTooltip>{ConvertRevitVersion.Convert(d.row.original.RevitVersion)}</OverflowTooltip>
                  },
                  {
                    id: 'Checkset',
                    accessorFn: t => t.Checkset?.name,
                    header: () => 'Checkset',
                    cell: d => <OverflowTooltip>{d.row.original.Checkset?.name}</OverflowTooltip>
                  },
                  {
                    id: 'LastRun',
                    accessorFn: t => t.LastRun,
                    size: 100,
                    sortingFn: 'datetime',
                    header: () => 'Last Run',
                    cell: d =>
                      <OverflowTooltip>{ConvertRunDate.Convert(d.row.original.LastRun)}</OverflowTooltip>
                  },
                  {
                    id: 'NextRun',
                    accessorFn: t => t.NextRun,
                    size: 100,
                    sortingFn: 'datetime',
                    header: () => 'Next Run',
                    cell: d => <EllipsisCell>{ConvertRunDate.Convert(d.row.original.NextRun)}</EllipsisCell>
                  },
                  {
                    id: 'actions',
                    size: 120,
                    header: () => 'Actions',
                    cell: d => renderActionCell(d.row.original)
                  },
                ]}
                data={state.filteredTasks}
                renderLastRow={() => state.hasMoreData &&
                  <LoadMoreDataRow
                    isLoading={state.loadingMoreData}
                    onLoad={async () => loadTasks(false, false)}
                    renderLoadMore={() =>
                      <FlexRowCentered>
                        <LinkButton onClick={() => loadTasks(false, false)}>
                          <span style={Theme.typography.bodySmall}>Load more</span>
                        </LinkButton>
                        <LinkButton onClick={() => loadTasks(false, true)} style={{marginLeft: '1em'}}>
                          <span style={Theme.typography.bodySmall}>Load all</span>
                        </LinkButton>
                      </FlexRowCentered>
                    }/>
                }/>
            </FlexColumn>
          }
          {
            state.loading &&
            (<CenteringContainer>
              <FlexColumnCentered>
                <ProgressRing size={'large'}/>
                {
                  state.canCancelLoad &&
                  <LinkButton onClick={() => cancelToken.Cancel = true}>Cancel</LinkButton>
                }
              </FlexColumnCentered>
            </CenteringContainer>)
          }
          {
            !state.loading && state.tasks.length === 0 &&
            <CenteringContainer style={{flexDirection: 'column'}}>
              <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
              <p style={Theme.typography.bodyLarge}>You don't have any tasks yet</p>
            </CenteringContainer>
          }
        </ContentWrapper>
      </ContentScroller>
      <Panel
        title={'Filter Tasks'}
        open={state.filterOpen}
        style={{height: 'auto'}}
        onClose={() => dispatch({type: TasksActions.filterOpen, payload: false})}>
        <Panel.Body>
          <div>
            {state.filterOptions.map(o => {
              return (
                <FilterItem key={o.title} selected={o.selected} item={o}
                            onSelectionChange={() => updateFilteredData()}/>
              );
            })}
          </div>
        </Panel.Body>
      </Panel>
      <Modal open={state.showUsageDetail}>
        <Modal.Header>Item Detail</Modal.Header>
        <Modal.Body>
          {
            state.loadingUsageData &&
            <CenteringContainer style={{overflow: 'hidden'}}>
              <ProgressRing size={'large'}/>
            </CenteringContainer>
          }
          {
            !state.loadingUsageData && state.usageDetail != null &&
            <UsageDetail detailObject={state.usageDetail}/>
          }
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => dispatch({type: TasksActions.showUsageDetail, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Close</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </Panel.Container>
  );
};

export default TaskList;
