////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react';
import {
    BlueButton,
    CheckSetIcon,
    CheckSetItem,
    ColumnLeft, ColumnRight, FlexColumn,
    FlexFill,
    FlexRow,
    FlexRowCentered
} from "../CommonStyledComponents";
import { CheckSet } from "../clients/Classes";
import Default_Checkset from '../assets/Default_Checkset.png';
import {IconButton} from "@adsk/alloy-react-button";
import {PencilIcon, TrashCanIcon} from "@adsk/alloy-react-icon";
import Theme from "@adsk/alloy-react-theme";
import Modal from "@adsk/alloy-react-modal";
import Tooltip from "@adsk/alloy-react-tooltip";
import TextInput from "@adsk/alloy-react-text-input";

const CheckSetListItem = (
    {
        checkset,
        isSelected,
        editable,
        onClick,
        onDelete,
        onEdit,
    }: {
        checkset: CheckSet,
        isSelected: boolean,
        editable?: boolean,
        onClick?: () => void,
        onDelete?: () => void,
        onEdit?: () => void,
    }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [name, setName] = useState(checkset.name);
    const [description, setDescription] = useState(checkset.description);

    function startEdit(): void {
        setShowEdit(true);
    }

    function finishEdit(): void {
        checkset.name = name;
        checkset.description = description;
        setShowEdit(false);

        if (onEdit) {
            onEdit();
        }
    }

    function sendDelete(): void {
        if (onDelete) {
            onDelete();
        }
    }

    return (
        <CheckSetItem
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            style={isSelected ? { background: Theme.colors._greyAlpha } : {}}>
            <CheckSetIcon
                src={checkset.imagePath == null || checkset.imagePath.trim() === '' ? Default_Checkset : checkset.imagePath}
                alt={'Checkset Avatar'} />
            <span style={{ margin: '0 1em' }}>{checkset.name}</span>
            <FlexFill />
            {
                editable &&
                <>
                    <IconButton onClick={startEdit}><PencilIcon /></IconButton>
                    <IconButton onClick={sendDelete}><TrashCanIcon /></IconButton>
                </>
            }
            <Modal open={showEdit}>
                <Modal.Header>Edit Check Set</Modal.Header>
                <Modal.Body>
                    <FlexColumn style={{ height: '100%' }}>
                        <FlexRowCentered>
                            <ColumnLeft>
                                <Tooltip content={'The display name of the check set'}>
                                    Name
                                </Tooltip>
                            </ColumnLeft>
                            <ColumnRight>
                                <TextInput value={name}
                                           onChange={e => setName(e.target.value)} />
                            </ColumnRight>
                        </FlexRowCentered>
                        <FlexRowCentered>
                            <ColumnLeft>
                                <Tooltip content={'The display name of the check set'}>
                                    Description
                                </Tooltip>
                            </ColumnLeft>
                            <ColumnRight>
                                <TextInput value={description}
                                           onChange={e => setDescription(e.target.value)} />
                            </ColumnRight>
                        </FlexRowCentered>
                    </FlexColumn>
                </Modal.Body>
                <Modal.Footer>
                    <FlexRow style={{ justifyContent: 'end' }}>
                        <BlueButton onClick={() => finishEdit()}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>Save</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <BlueButton style={{ marginLeft: '1em' }}
                                    onClick={() => setShowEdit(false)}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>Cancel</span>
                            </FlexRowCentered>
                        </BlueButton>
                    </FlexRow>
                </Modal.Footer>
            </Modal>
        </CheckSetItem>
    );
};

export default CheckSetListItem;